<div class="content">
  <div class="container">
    <div class="row column-min-wh-resize">
      <p class="h5">Welcome!</p>
      <div class="col-md-6 flex-wrap column-min-wh-resize limits-container">
        <h6>Here you can set up interview process limitations to make it more convenient. </h6>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="row">

            <div class="col-md-8">
              <div class="form-group">
                <label for="max-per-day" class="control-label">Maximum per day:</label>
                <input type="number" class="form-control text-lg-end col-3" formControlName="dailyMax" id="max-per-day"
                       placeholder="Enter a number" [min]="0" [max]="10">

                <div *ngIf="form.get('dailyMax').invalid && (form.get('dailyMax').dirty || form.get('dailyMax').touched)">
                  <div *ngIf="form.get('dailyMax').errors['pattern']" class="input-error">Only integer numbers are allowed</div>
                  <div *ngIf="form.get('dailyMax').errors['min'] || form.get('dailyMax').errors['max']"
                       class="input-error">Only numbers between 0 and 10 allowed</div>
                </div>
                <div class="text-muted field-hint">
                  0 not assign interview. Leave empty for no limits. Max is 10
                </div>
              </div>
            </div>

           <div class="col-md-8">
             <div class="form-group">
               <label for="max-per-week" class="control-label">Maximum per week:</label>
               <input type="number" class="form-control text-lg-end col-3" formControlName="weeklyMax" id="max-per-week"
                      placeholder="Enter a number" [min]="0" [max]="20">
               <div *ngIf="form.get('weeklyMax').invalid && (form.get('weeklyMax').dirty || form.get('weeklyMax').touched)">
                 <div *ngIf="form.get('weeklyMax').errors['pattern']" class="input-error">Only integer numbers are allowed</div>
                 <div *ngIf="form.get('weeklyMax').errors['min'] || form.get('weeklyMax').errors['max']"
                      class="input-error">Only numbers between 0 and 20 allowed</div>
                 <div *ngIf="form.get('weeklyMax').errors['invalidValue']" class="input-error">Week limit shouldn't be less than day limit</div>
               </div>
               <div class="text-muted field-hint">
                 0 not assign interview. Leave empty for no limits. Max is 20
               </div>
             </div>
           </div>

            <div class="mt-4 col-md-8">
              <div class="button">
                <button [disabled]="inProgress" [disabled]="this.form.invalid"  class="btn custom-btn" type="submit">
                  <span *ngIf="inProgress" class="spinner-border sr-only"></span>&nbsp;Set limits
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="col-md-6">
        <div *ngIf="isLoadingSlots" class="d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status"></div>
        </div>

        <div *ngIf="!isLoadingSlots && slots.length > 0">
          <h6>Your proposed interview slots:</h6>
          <div class="row slot-container">
            <div *ngFor="let group of slots" class="col-md-6">
              <div class="row slot-day">
                <div class="col-md-4">
                  <p class="slot-date">{{ group.date }}</p>
                  <p class="text-muted">{{ group.dayOfWeek }}</p>
                </div>

                <div class="col-md-8">
                  <div class="row slot-times">
                    <div class="col-6" *ngFor="let time of group.times">
                      <span [class.busy]="time.busy">{{ time.interval }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isLoadingSlots && slots.length === 0">
          <h6>This is how you can provide your availability.</h6>
          <p>If your availability isn’t reflected in your DXC calendar (e.g., you track meetings in a client’s calendar or can accommodate overlapping interviews despite a full schedule), please submit your slots by sending a meeting invitation to <strong>slot&#64;dxc.com</strong>:</p>
          <img src="assets/images/placeholder-image.png" alt="Slots Submission Guide" class="img-fluid">
          <br><br>
          <p>We kindly ask you to set a recurring slot if you are confident in your long-term availability. Here are the detailed <a style="cursor: pointer; color:#004180;text-decoration:none;" (click)="downloadInstruction()">instruction.</a><mat-icon style="cursor: pointer; color:#004180" (click)="downloadInstruction()" class="download">file_download</mat-icon></p>
        </div>
      </div>
    </div>
  </div>
</div>
