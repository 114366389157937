<div class="content">
  <footer class="footer">
    <section class="footer__body">
      <div class="container">
        <div class="footer__row">
          <div class="col-12">
            <div class="footer__copyright">
              <p class="info">© Luxoft. All rights reserved.</p>
              <ul class="links">
                <li class="list-border"><a href="https://www.luxoft.com/">LUXOFT</a></li>
                <li class="list-border"><a href="https://www.luxoft.com/online-privacy-policy/">PRIVACY POLICY</a></li>
                <li class=''><a href="mailto:luxhiresupport@luxoft.com">CONTACT SUPPORT</a></li>
              </ul>
              <p class="info-small">© Luxoft. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</div>
