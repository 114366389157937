import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LimitsSettings} from "../dto/limits-settings";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LimitsService {
  private url = environment.apiUrl + "limits";
  private urlFile = environment.apiUrl + "files";
  private urlSlots = environment.apiUrl + "slots";
  constructor(private http: HttpClient) { }

  getAvailableSlots(username: string): Observable<any[]> {
    return this.http.get<any[]>(this.urlSlots + `/${username}`);
  }

  saveLimits(limits: LimitsSettings) {
    return this.http.post<LimitsSettings>(this.url, limits);
  }

  getLimitsByUsername(username: string) {
    return this.http.get<LimitsSettings>(this.url + `/${username}`);
  }

  logLogin(login: string, eventTime: Date){
    const data = {
      "event_date": eventTime.toISOString(),
      "event_name": "Login Page Open",
      "login": login
    }
    this.http.post(this.url + "/log", data).subscribe();
  }

  logSettingsUpdate(login: String, eventTime: Date, dailyMax: String, weeklyMax: String ){
    const data = {
      "event_date": eventTime.toISOString(),
      "event_name": "Limits updated",
      "login": login,
      "event_data": {
        "daily_maximum" : dailyMax,
        "weekly_maximum" : weeklyMax
      }
    }
    console.log(data);
    this.http.post(this.url + "/log", data).subscribe();
  }

  downloadFileByName(fileName: string) {
    return this.http.get(this.url + `/attachments/${fileName}`, {responseType: 'blob'});
  }
}
