import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {AuthContextService} from "./auth-context.service";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authContext: AuthContextService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.authContext.isTokenEmpty() &&
      (req.url.includes("/limits") ||
          req.url.includes("/files") ||
          req.url.includes("/slots"))){
      req = req.clone({
        setHeaders: {
          Authorization : `${this.authContext.token}`
        }
      });
    }
    return next.handle(req).pipe(
      catchError((error: any) => {
        throw error; // Rethrow the error to propagate it
      })
    );
  }

}
